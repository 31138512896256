import { graphql, Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Layout, ExternalLink } from '@common';
import { Container, Button } from '@components/global';
import { useImage, changeToUrl, colorMap, handleDate } from '@utils';
import {
  StyledSection,
  Subheader,
  Content,
  Image,
  EventTag,
  ButtonContainer,
} from './style';

const EventTemplate = ({ data }) => {
  const event = data.event;
  const imgUrl = useImage(changeToUrl(event.title));
  const arrPembicaraLength = event.pembicara ? event.pembicara.length : 0;
  const date = handleDate(event.tanggal, event.timestamp, event.hiddenTag);
  const [countRegistrant, setCountRegistrant] = useState();
  const oneHour = 3600000;

  useEffect(() => {
    firebase
      .database()
      .ref(`/events/${changeToUrl(event.title)}/count_registrant`)
      .on('value', snapshot => setCountRegistrant(snapshot.val()));
  });
  return (
    <Layout page={event.title} includeHeading={false}>
      <StyledSection>
        <Container>
          <Content split={event.desc}>
            <div>
              <h2>{event.title}</h2>
              <Subheader>
                <p>{date}</p>
                <EventTag color={colorMap[event.type]} type={event.type} />
                {event.by.map(univ => (
                  <EventTag key={univ} color={colorMap[univ]} by={univ} />
                ))}
              </Subheader>
              {event.desc ? (
                <React.Fragment>
                  <h3>Deskripsi</h3>
                  <p>{event.desc}</p>
                  <br />
                </React.Fragment>
              ) : null}
              {event.keynote ? (
                <React.Fragment>
                  <h3>Keynote Speaker</h3>
                  <p style={{ marginBottom: '12px' }}>{event.keynote}</p>
                </React.Fragment>
              ) : null}
              {arrPembicaraLength ? (
                <React.Fragment>
                  <h3>Pembicara</h3>
                  <p style={{ marginBottom: '12px' }}>
                    {event.pembicara.map((el, idx) => (
                      <React.Fragment key={idx}>
                        {el}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </React.Fragment>
              ) : null}
              {countRegistrant ? (
                <h4>Jumlah Pendaftar: {countRegistrant}</h4>
              ) : null}
              <ButtonContainer>
                {event.rekaman ? (
                  <Button>
                    <ExternalLink href={event.rekaman}>
                      Tonton Video
                    </ExternalLink>
                  </Button>
                ) : null}
                {event.materi ? (
                  <Button>
                    <ExternalLink href={event.materi}>Materi</ExternalLink>
                  </Button>
                ) : null}
                {new Date().getTime() < Number(event.timestamp-oneHour) ? (
                  <Link to={'daftar'}>
                    <Button>Daftar</Button>
                  </Link>
                ) : null}
              </ButtonContainer>
            </div>
            <Image>
              <ExternalLink href={imgUrl}>
                <img src={imgUrl} alt={event.title} />
              </ExternalLink>
            </Image>
          </Content>
        </Container>
      </StyledSection>
    </Layout>
  );
};

export default EventTemplate;
export const pageQuery = graphql`
  query EventById($id: String!) {
    event(id: { eq: $id }) {
      pembicara
      title
      type
      desc
      id
      by
      tanggal: date(formatString: "dddd, DD MMMM YYYY", locale: "id")
      timestamp: date(formatString: "x")
      rekaman
      materi
      keynote
      hiddenTag: hidden_tag
    }
  }
`;
