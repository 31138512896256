import styled from 'styled-components';
import { Section } from '@components/global';
import { Tag } from '@common';

export const Image = styled.div`
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    height: inherit;
  }
`;

export const Subheader = styled.div`
  margin: 0 auto 12px;
  & > * {
    margin-bottom: 6px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 48px;
  justify-items: center;
  align-items: center;
  margin-top: 24px;

  p {
    text-align: justify;
  }

  ${Image} {
    order: -1;
    max-width: 500px;
  }

  ${props =>
    props.split &&
    `
    grid-template-columns: 1fr 1fr;
    ${Image} {
      order: 2;
    }
  `}

  @media(max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    ${Image} {
      order: -1;
    }
  }
`;

export const StyledSection = styled(Section)`
  min-height: 77.5vh;
`;

export const EventTag = styled(Tag)`
  margin-right: 5px;
`;

export const Paragraph = styled.p`
  width: 80%;
  text-align: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 60%;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }
`;
export const ButtonContainer = styled.div`
  & > * {
    margin-right: 10px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;

  & > div {
    width: 60%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    & > div {
      width: 100%;
    }
  }
`;

export const EndEventContainer = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const OfficerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  grid-gap: 3rem;
  grid-template-rows: min-content;

  @media (max-width: ${props => props.theme.screen.lg}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;
